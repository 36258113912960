<zui-loader-box *ngIf="isLoading" headline="{{ 'LOADING.HEADLINE' | translate }}"
    description="{{ 'LOADING.DESCRIPTION' | translate }}"></zui-loader-box>
<ng-container *ngIf="!isLoading">
    <app-header-bar></app-header-bar>
    <router-outlet></router-outlet>
</ng-container>

<zui-toast-notification class="toast" *ngIf="errorToastStream$ | async; let error"
    headerText="{{ error.headerText! | translate }}" state="default" closable
    (zui-toast-notification-closed)="closeToast()">
    <zui-icon-alerts-ewi-error-triangle slot="icon"></zui-icon-alerts-ewi-error-triangle>
    <p *ngIf="!error.individualText; else individualText">
        <span *ngIf="error.code"> {{ error.code }} </span> <span *ngIf="error.message"> - {{ error.message | translate
            }} </span>
    </p>
    <ng-template #individualText>
        <p>
            <span>
                {{ error.individualText }}
            </span>
        </p>
    </ng-template>
</zui-toast-notification>

<zui-toast-notification class="toast" *ngIf="successToastStream$ | async; let success"
    headerText="{{ success.headerText! | translate }}" state="default" closable
    (zui-toast-notification-closed)="closeToast()">
    <zui-icon-alerts-ewi-check slot="icon"></zui-icon-alerts-ewi-check>
    <p>
        <span *ngIf="success.message"> {{ success.message | translate }} </span>
    </p>
</zui-toast-notification>