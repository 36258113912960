import { DatePipe } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ErrorMessage, IncomingGoodsBookingDetails, IncomingGoodsBookingRequestStatus, Order } from "src/app/common/models/order.type";
import { User } from "src/app/common/models/user.type";
import { ToastService } from "src/app/common/services/toast/toast.service";
import { OrderService } from "src/app/main/services/order/order.service";

@Component({
    selector: 'app-incoming-goods-booking',
    templateUrl: './incoming-goods-booking.component.html',
    styleUrls: ['./incoming-goods-booking.component.scss'],
})
export class IncomingGoodsBookingComponent {
    @Input() public order!: Order;
    @Input() public canExecuteIncomingGoodsBooking: boolean = false;

    @Output() refreshView: EventEmitter<void> = new EventEmitter<void>();

    public INCOMING_GOODS_BOOKING_REQUEST_STATUS = IncomingGoodsBookingRequestStatus;
    public user!: User;
    public isSubmitDialogVisible: boolean = false;
    public isErrorDetailsDialogVisible: boolean = false;
    public bookingDetails?: IncomingGoodsBookingDetails;

    constructor(
        readonly orderService: OrderService,
        readonly toastService: ToastService,
        readonly datePipe: DatePipe
    ) { }

    public showSubmitDialog(): void {
        this.isSubmitDialogVisible = true;
    }

    public hideSubmitDialog(): void {
        this.isSubmitDialogVisible = false;
    }

    public showErrorDetailsDialog(detailsError: 'error' | 'details'): void {
        if(detailsError === 'details') {
            this.bookingDetails = {
                materialReceipt: this.order.materialReceipt,
                materialReceiptPosition: this.order.materialReceiptPosition,
                materialReceiptYear: this.order.materialReceiptYear,
                name: this.order.incomingGoodsBookingRequestBy?.firstName + ' ' + this.order.incomingGoodsBookingRequestBy?.lastName,
                timeStamp: this.datePipe.transform(this.order.incomingGoodsBookingRequestAt, 'dd.MM.yyyy HH:mm') ?? ''
            };
        }
        this.isErrorDetailsDialogVisible = true;
    }

    public hideErrorDetailsDialog(): void {
        this.isErrorDetailsDialogVisible = false;
    }

    public executeIncomingGoodsBookingRequest(): void {
        this.orderService.executeIncomingGoodsBookingRequest(this.order.id).subscribe({
            next: () => {
                this.toastService.emitSuccess({
                    message: 'INCOMINGGOODSBOOKING'
                });
                this.refreshView.emit();
            },
            error: (error: HttpErrorResponse) => {
                let individualText: string = '';
                error.error.forEach((e: ErrorMessage) => { 
                    individualText += e.message + '. ';
                });
                this.toastService.emitError({
                    message: 'INCOMINGGOODSBOOKING',
                    code: error.status,
                    individualText: individualText
                });
            }
        });
        this.isSubmitDialogVisible = false;
    }
}
